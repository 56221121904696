import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemText,
  ListItem,
  List,
  Typography,
  Divider
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  total: {
    fontWeight: "700"
  },
  title: {
    marginTop: theme.spacing(2)
  }
}));

export default function Review(props) {
  const classes = useStyles();
  const { price, total } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom align="center">
        Bitte kontrollieren Sie Ihre Bestellung.
      </Typography>
      <List disablePadding>
        {Object.keys(price).map(product => (
          <ListItem className={classes.listItem} key={price[product]["title"]}>
            <ListItemText
              primary={price[product]["title"]}
              secondary={price[product]["unit"]}
            />
            <Typography variant="body2">€ {price[product]["price"]}</Typography>
          </ListItem>
        ))}
        <Divider />
        <ListItem className={classes.listItem}>
          <ListItemText primary="Gesamtbetrag" />
          <Typography variant="h6" className={classes.total}>
            € {total}
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
