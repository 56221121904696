import {
  Boarbichler_Hofkistl2,
  Boarbichler_Kasebox,
  JoggeleWeiss,
  Kaminwurzen,
  Knottnkas,
  Kraeutermandl,
  Rebell,
  SchmugglerLuis,
  Weibele,
  Xaver,
  Joghurt,
  TallnerHart,
  Wochentaschl,
  GeschenkBrettl
} from "../static";

const data = [
  {
    title: "S' Weibele",
    image: Weibele,
    description:
      "Milder frischer Schnittkäse, ähnlich wie Mozzarella. 10 Tage im Vakuum gereift.",
    subDescription: "20€/kg - Vakuumiert verpackt, 10 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "Weibele0g",
        unit: "0g",
        price: 0
      },
      {
        id: "Weibele200g",
        unit: "200g",
        price: 4.0
      },
      {
        id: "Weibele400g",
        unit: "400g",
        price: 8.0
      },
      {
        id: "Weibele600g",
        unit: "600g",
        price: 12.0
      },
      {
        id: "Weibele800g",
        unit: "800g",
        price: 16.0
      },
      {
        id: "Weibele1000g",
        unit: "1000g",
        price: 20.0
      }
    ]
  },
  {
    title: "Joggele Weiss",
    image: JoggeleWeiss,
    description: "Milder Weichkäse mit Weißschimmel mit 14 Tagen Reifung.",
    subDescription: "28.5€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0 
      },
      {
        id: "200g",
        unit: "200g",
        price: 5.7
      },
      {
        id: "400g",
        unit: "400g",
        price: 11.4
      },
      {
        id: "600g",
        unit: "600g",
        price: 17.1
      },
      {
        id: "800g",
        unit: "800g",
        price: 22.8
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 28.5
      }
    ]
  },
  {
    title: "Kräutermandl",
    image: Kraeutermandl,
    description:
      "Leicht würziger Schnittkäse mit Brotgewürz. 14 Tage Reifung.",
    subDescription: "24.5€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 4.9
      },
      {
        id: "400g",
        unit: "400g",
        price: 9.8
      },
      {
        id: "600g",
        unit: "600g",
        price: 14.7
      },
      {
        id: "800g",
        unit: "800g",
        price: 19.6
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 24.5
      }
    ]
  },
  {
    title: "Rebell",
    image: Rebell,
    description: "Leicht würziger Schnittkäse, 4-6 Wochen Reifung.",
    subDescription: "24.5€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 4.9
      },
      {
        id: "400g",
        unit: "400g",
        price: 9.8
      },
      {
        id: "600g",
        unit: "600g",
        price: 14.7
      },
      {
        id: "800g",
        unit: "800g",
        price: 19.6
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 24.5
      }
    ]
  },
  {
    title: "Schmuggler Luis",
    image: SchmugglerLuis,
    description:
      "Würzig-pfeffriger Schnittkäse mit Pfefferkorn, 4-6 Wochen Reifung.",
    subDescription: "24.5€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 4.9
      },
      {
        id: "400g",
        unit: "400g",
        price: 9.8
      },
      {
        id: "600g",
        unit: "600g",
        price: 14.7
      },
      {
        id: "800g",
        unit: "800g",
        price: 19.6
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 24.5
      }
    ]
  },
  {
    title: "Xaver",
    image: Xaver,
    description:
      "Leicht scharfer Schnittkäse mit Peperoncino, 4-6 Wochen Reifung.",
    subDescription: "24.5€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 4.9
      },
      {
        id: "400g",
        unit: "400g",
        price: 9.8
      },
      {
        id: "600g",
        unit: "600g",
        price: 14.7
      },
      {
        id: "800g",
        unit: "800g",
        price: 19.6
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 24.5
      }
    ]
  },
  {
    title: "Knottn Kaas",
    image: Knottnkas,
    description: "Würzig-eleganter halbharter Schnittkäse, 3-6 Monate Reifung.",
    subDescription: "28€/kg - Vakuumiert verpackt, 14 Tage haltbar.",
    available: false,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 5.6
      },
      {
        id: "400g",
        unit: "400g",
        price: 11.2
      },
      {
        id: "600g",
        unit: "600g",
        price: 16.8
      },
      {
        id: "800g",
        unit: "800g",
        price: 22.4
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 28.0
      }
    ]
  },
  {
    title: "Tallner Hart",
    image: TallnerHart,
    description: "Hartkäse würzig, 18 Monate Reifung",
    subDescription: "29€/kg - Vakuumiert verpackt, 21 Tage haltbar.",
    available: false,
    dropDown: [
      {
        id: "0g",
        unit: "0g",
        price: 0
      },
      {
        id: "200g",
        unit: "200g",
        price: 5.8
      },
      {
        id: "400g",
        unit: "400g",
        price: 11.6
      },
      {
        id: "600g",
        unit: "600g",
        price: 17.4
      },
      {
        id: "800g",
        unit: "800g",
        price: 23.2
      },
      {
        id: "1000g",
        unit: "1000g",
        price: 29.0
      }
    ]
  },
  {
    title: "Kaminwurzen",
    image: Kaminwurzen,
    description:
      "Kaminwurzen Rind Fein würzig, rauchiges Aroma, luftgetrocknet.",
    subDescription: "2€/Stück - Vakuumiert verpackt, 30 Tage haltbar.",
    available: true,
    dropDown: [
      {
        id: "Kaminwurzen1",
        unit: "0",
        price: 0
      },
      {
        id: "Kaminwurzen1",
        unit: "1",
        price: 2.0
      },
      {
        id: "Kaminwurze2",
        unit: "2",
        price: 4.0
      },
      {
        id: "Kaminwurze6",
        unit: "4",
        price: 8.0
      },
      {
        id: "Kaminwurzen8",
        unit: "8",
        price: 16.0
      }
    ]
  },
  {
    title: "Joghurt",
    image: Joghurt,
    description:
      "Joghurt natur, mild",
    subDescription: "3.6€/Stück - Glas, 580ml Inhalt, 14 Tage haltbar",
    available: true,
    dropDown: [
      {
        id: "Joghurt0",
        unit: "0",
        price: 0
      },
      {
        id: "Joghurt1",
        unit: "1",
        price: 3.6
      },
      {
        id: "Joghurt2",
        unit: "2",
        price: 7.2
      },
      {
        id: "Joghurt3",
        unit: "3",
        price: 14.4
      },
      {
        id: "Joghurt4",
        unit: "4",
        price: 21.6
      }
    ]
  },
  {
    title: "Geschenkbox",
    image: Boarbichler_Kasebox,
    description:
      "18x18 cm, beinhaltet 3 verschiedene Käsesorten und 2 Kaminwurzen.",
    subDescription: "25€/Stück",
    available: true,
    dropDown: [
      {
        unit: "0",
        price: 0
      },
      {
        unit: "1",
        price: 25
      },
      {
        unit: "2",
        price: 50
      },
      {
        unit: "3",
        price: 75
      },
      {
        unit: "4",
        price: 100
      },
      {
        unit: "5",
        price: 125
      }
    ]
  },
  {
    title: "Boarbichler Hofkistl",
    image: Boarbichler_Hofkistl2,
    description:
      "Handerlesene Käse und Wurst Premiumselektion aus dem gesamten Boarbichl Hofsortiment.",
    subDescription: "45€/Stück",
    available: true,
    dropDown: [
       {
        unit: "0",
        price: 0
      },
      {
        unit: "1",
        price: 45
      },
      {
        unit: "2",
        price: 90
      },
      {
        unit: "3",
        price: 135
      },
      {
        unit: "4",
        price: 180
      },
      {
        unit: "5",
        price: 225
      }
    ]
  },
  {
    title: "s'Wochentaschl",
    image: Wochentaschl,
    description:
      "1 Joghurt, 3 verschiedene Käsesorten (total ca. 700g), 6 Eier, 2 Kaminwurzen",
    subDescription: "30€/Stück",
    available: true,
    dropDown: [
      {
        unit: "0",
        price: 0
      },
      {
        unit: "1",
        price: 30
      },
      {
        unit: "2",
        price: 60
      },
      {
        unit: "3",
        price: 90
      },
      {
        unit: "4",
        price: 120
      },
      {
        unit: "5",
        price: 150
      }
    ]
  },
  {
    title: "Geschenk-Brettl",
    image: GeschenkBrettl,
    description:
      "1 Laib Käse (ca.1kg), 4 Kaminwurzen Rind, hand-gemachtes Schneidebrett (35x25cm)",
    subDescription: "45€/Stück",
    available: true,
    dropDown: [
      {
        unit: "0",
        price: 0
      },
      {
        unit: "1",
        price: 45
      },
      {
        unit: "2",
        price: 90
      },
      {
        unit: "3",
        price: 135
      },
      {
        unit: "4",
        price: 180
      },
      {
        unit: "5",
        price: 225
      }
    ]
  }
];

export { data as default};
