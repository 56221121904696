import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
  Typography
} from "@material-ui/core";
import axios from "axios";
import config from "./config";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    margin: "1rem auto"
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 400
  }
});

const calculateTotal = object => {
  return Object.keys(object)
    .reduce((acc, value) => acc + object[value]["price"], 0)
    .toFixed(2);
};

const Orders = props => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);

  const { history, match } = props;
  const routeParams = match.params;

  /**
   * Load all data.
   */
  useEffect(() => {
    (async () => {
      if (routeParams.key) {
        try {
          let response = null;
          response = await axios.get(
            `${config.apiBaseUrl}/api/get-orders?key=${routeParams.key}`
          );
          setOrders(response.data);
        } catch (err) {
          history.push("/");
        }
      }
    })();
  }, [history, routeParams]);

  const getOrderItems = object => {
    return Object.keys(object).map(row => (
      <StyledTableCell key={row}>
        <b>Title:</b> {object[row]["title"]} <br />
        <b>Unit:</b> {object[row]["unit"]}
        <br />
        <b>Price:</b> {object[row]["price"]}€
      </StyledTableCell>
    ));
  };

  return (
    <Grid container spacing={6} display="flex" justify="center">
      <Grid item xs={10}>
        <Typography variant="h4" color="inherit">
          Orders
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Vorname</StyledTableCell>
                <StyledTableCell>Nachname</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Telefonnummer</StyledTableCell>
                <StyledTableCell>NameIhrerUnterkunft</StyledTableCell>
                <StyledTableCell>Adresse</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Bemerkungen</StyledTableCell>
                <StyledTableCell>Total Price</StyledTableCell>
                <StyledTableCell>Product Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map(row => (
                <StyledTableRow key={row["_id"]}>
                  <StyledTableCell component="th" scope="row">
                    {row.vorname}
                  </StyledTableCell>
                  <StyledTableCell>{row.nachname}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.telefonnummer}</StyledTableCell>
                  <StyledTableCell>{row.nameIhrerUnterkunft}</StyledTableCell>
                  <StyledTableCell>{row.adresse}</StyledTableCell>
                  <StyledTableCell>{row.date}</StyledTableCell>
                  <StyledTableCell>{row.bemerkungen}</StyledTableCell>
                  <StyledTableCell>
                    {calculateTotal(JSON.parse(row.order))}€
                  </StyledTableCell>
                  <TableRow>{getOrderItems(JSON.parse(row.order))}</TableRow>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default Orders;
