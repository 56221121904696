import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import Orders from "./Orders";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from 'react-helmet'

const TITLE = 'Lieferservice Boarbichl';

class MyComponent extends React.PureComponent {
  render () {
    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-74713799-2"></script>
          <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-74713799-2');`}
          </script>
        </Helmet>
      </>
    )
  }
}

const routing = (
  <Router>
    <MyComponent></MyComponent>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/orders" component={Orders} />
      <Route path="/orders/:key" component={Orders} />
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
