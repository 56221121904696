import React from "react";
import { Typography } from "@material-ui/core";

export default function Review(props) {
  return (
    <React.Fragment>
<Typography variant="h6" gutterBottom align="center">
Vielen Dank für Ihre Bestellung!
</Typography>
<br />
<Typography variant="h6" gutterBottom align="center">
Sie erhalten in den nächsten 24h eine E-Mail mit ihrer Bestellung als Bestätigung und mit Details zu Lieferung und Zahlung.
Der Kaufvertrag ist erst dann gültig, wenn wir, die Bestellung per E-Mail bestätigen.
Sie bezahlen die Lieferung bei Übergabe der Lieferung. Nur Barzahlung ist möglich.
</Typography>
<br />
<Typography variant="h6" gutterBottom align="center">
Mit freundlichen Grüßen,
</Typography>
<br />
<Typography variant="h6" gutterBottom align="center">
Hofkäserei Boarbichl
</Typography>
    </React.Fragment>
  );
}
