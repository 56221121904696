import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {
  CardActions,
  CardActionArea,
  Grid,
  TextField,
  MenuItem
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import data from "../data/data.js";

const useStyles = makeStyles(theme => ({
  media: {
    height: 140,
    paddingTop: "56.25%"
  },
  content: {
    height: 150
  }
}));

export default function Products(props) {
  const classes = useStyles();
  const { price, setPrice } = props;

  const handleChange = (event, title) => {
    const values = event.target.value;
    const { id, ...other } = values;
    setPrice({
      ...price,
      [title]: { title, ...other }
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          {/* <Box border={2} borderColor="grey.500" borderRadius={5} p={5} bgcolor="#fad0c4">
          <Typography variant="h5">
            Update 14. September 2020:<br /><br />
            Liebe Kunden, unser Käselager ist leer...<br />
            Bestellungen im Online Shop sind 2021 wieder möglich.<br />
          </Typography>
          </Box> */}
          {
          <Typography variant="h6">
          <ul>
            <li>Mindestbestellwert &euro;18</li>
			      <li>Kostenfreie Lieferung nach Meran und Umgebung, Dienstags und Freitags</li>
          </ul>
          </Typography>
          }
        </Grid>
        {data.map((data, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <Card>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={data.image}
                  title={data.title}
                />
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant="subtitle1">
                    <b>{data.title}</b>
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    {data.subDescription}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {data.available ?
              <CardActions>
              <TextField
                select
                fullWidth
                label="Menge auswählen..."
                onChange={e => handleChange(e, data.title)}
                variant="outlined"              >
                {data.dropDown.map((option, index) => (
                  <MenuItem key={index} value={option} bgcolor="#C8C8C8">
                    {option.unit}
                  </MenuItem>
                ))}
              </TextField>
            </CardActions>
              :
              <center>
              <Box border={1} color="grey.600" borderColor="grey.400" borderRadius={5} p={2} m={1} bgcolor="#FFFFFF">
              Ausverkauft
              </Box>
            </center>
              }
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
